class FirebaseConfig {
    apiKey = "";
    authDomain = "";
    databaseURL = "";
    projectId = "";
    storageBucket = "";
    messagingSenderId = "";
}

class ApplicationConfig {
    apiHost = "";
    firebase = new FirebaseConfig();
}

let config = new ApplicationConfig();
config.firebase = {
    apiKey: "AIzaSyBDVYB7I38cQW708TJS5yFfWRRelEu2_8M",
    authDomain: "qos-orchestrator-dev.firebaseapp.com",
    databaseURL: "https://qos-orchestrator-dev.firebaseio.com",
    projectId: "qos-orchestrator-dev",
    storageBucket: "qos-orchestrator-dev.appspot.com",
    messagingSenderId: "281298976709"
};

console.log(`NODE_ENV[${process.env.NODE_ENV}] RELEASE[${process.env.REACT_APP_RELEASE_BUILD}]`);
if(process.env.NODE_ENV === "development"){
    // LOCAL DEV
    config.apiHost = "http://localhost:3000";
}else if(process.env.NODE_ENV === "production" && (!process.env.REACT_APP_RELEASE_BUILD || process.env.REACT_APP_RELEASE_BUILD === "false")){
    // AWS DEV (staging)
    config.apiHost = "https://v9efpq3i37.execute-api.us-west-2.amazonaws.com/dev";
}else if(process.env.NODE_ENV === "production" && process.env.REACT_APP_RELEASE_BUILD === "true"){
    // AWS PROD
    config.firebase = {
        apiKey: "AIzaSyAK_TYPyKSABKuk8o2PtWsas4M1kKkaT8U",
        authDomain: "qos-orchestrator.firebaseapp.com",
        databaseURL: "https://qos-orchestrator.firebaseio.com",
        projectId: "qos-orchestrator",
        storageBucket: "qos-orchestrator.appspot.com",
        messagingSenderId: "689271143998"
    };
    config.apiHost = "https://zts23vhqi2.execute-api.us-west-2.amazonaws.com/prod";
}
console.log(config.apiHost);

export default config;