import React, {Component} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "assets/vendors/style"
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';

import MainApp from 'app/index';
import SignIn from './SignIn';
import {setInitUrl} from '../actions/Auth';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import ResetPassword from './ResetPassword';
import qs from 'query-string';

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/login',
            state: {from: props.location}
          }}
        />}
  />;

class UserActions extends Route {
  render() {
    const mode = qs.parse(this.props.location.search).mode;
    switch(mode){
      case "resetPassword":
        return <Redirect to={{ pathname: "/reset", search: this.props.location.search }}></Redirect>
      default:
        return <Redirect to="/login"></Redirect>
    }
  }
}

class App extends Component {

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  render() {
    const {match, location, locale, authUser, initURL} = this.props;
    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/login'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/login') {
        return ( <Redirect to={'/manage/siem'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }
    const applyTheme = createMuiTheme(defaultTheme);

    // if (isDirectionRTL) {
    //   applyTheme.direction = 'rtl';
    //   document.body.classList.add('rtl')
    // } else {
    //   document.body.classList.remove('rtl');
    //   applyTheme.direction = 'ltr';
    // }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute path={`${match.url}manage`} authUser={authUser} component={MainApp}/>
                  <Route path='/login' component={SignIn}/>
                  <Route path='/reset' component={ResetPassword}/>
                  <UserActions path="/user/action"></UserActions>
                  <Route component={asyncComponent(() => import('components/Error404'))}/>
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {sideNavColor, locale, isDirectionRTL} = settings;
  const {authUser, initURL} = auth;
  return {sideNavColor, locale, isDirectionRTL, authUser, initURL}
};

export default connect(mapStateToProps, {setInitUrl})(App);

