import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Redirect} from 'react-router-dom';
import qs from 'query-string';
import {
  hideMessage,
  resetPassword
} from 'actions/Auth';

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      confirm: "",
      password: "",
      canSubmit: false,
      invalidPasswordReason: "",
      invalidConfirmReason: ""
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 200);
    }
    if(previousState.password !== this.state.password || previousState.confirm !== this.state.confirm){
        this.validatePassword();
    }
  }

  submitLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const code = qs.parse(this.props.location.search).oobCode;
    this.props.resetPassword(code, this.state.password);
  }

  validatePassword = () => {
      const happy_state = {
        canSubmit: true,
        invalidPasswordReason: "",
        invalidConfirmReason: ""
      };

      if(this.state.password === ""){
        happy_state.invalidPasswordReason = "Please enter a password";
        happy_state.canSubmit = false;
      }else if(this.state.password.length < 6){
        happy_state.invalidPasswordReason = "Password should be 6 or more characters";
        happy_state.canSubmit = false;
      }else if(this.state.password !== this.state.confirm){
        happy_state.invalidConfirmReason = "Passwords do not match";
        happy_state.canSubmit = false;
      }

      this.setState(happy_state);
  }

  render() {
    if(this.props.resetSuccess){
        return <Redirect to="/login" />
    }

    const { canSubmit, invalidPasswordReason, invalidConfirmReason } = this.state;
    const {showMessage, loader, alertMessage} = this.props;
    
    return (
      <div className="app-reset-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-reset-main-content">

          <div className="app-reset-content">
            <div className="app-reset-header mb-4">
              <h1>QOS Networks</h1>
              <h3>Reset Password</h3>
            </div>

            <div className="app-reset-form">
              <form onSubmit={this.submitLogin}>
                <fieldset>
                  <TextField
                    error={invalidPasswordReason !== ""}
                    type="password"
                    label="Enter New Password"
                    fullWidth
                    onChange={(event) => this.setState({password: event.target.value})}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    autoFocus
                    helperText={invalidPasswordReason}
                  />
                  <TextField
                    error={invalidConfirmReason !== ""}
                    type="password"
                    label="Confirm Password"
                    fullWidth
                    onChange={(event) => this.setState({confirm: event.target.value})}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    helperText={invalidConfirmReason}
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button variant="contained" color="primary" type="submit" disabled={!canSubmit}>
                      Save Password
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>

        </div>
        <div className="loader-view" hidden={!loader}>
          <CircularProgress/>
        </div>
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer/>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser, resetSuccess} = auth;
  return {loader, alertMessage, showMessage, authUser, resetSuccess}
};

export default connect(mapStateToProps, {
  resetPassword,
  hideMessage,
})(ResetPassword);
