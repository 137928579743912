import React from 'react';
const pkg = require('../../../package.json');

const Footer = () => {
    return (
      <footer className="app-footer">
        <span className="d-inline-block">Copyright QOS Networks &copy; 2019 | v{pkg.version}</span>
      </footer>
    );
  }
;

export default Footer;
