import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    auth
} from "../firebase/firebase";
import {
    SIGNIN_USER,
    SIGNOUT_USER,
    RESET_PASSWORD
    // SIGNUP_USER
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, showAuthLoader, hideAuthLoader} from "actions/Auth";
import { resetPasswordSuccess } from "../actions/Auth";

// const createUserWithEmailPasswordRequest = async (email, password) =>
//     await  auth.createUserWithEmailAndPassword(email, password)
//         .then(authUser => authUser)
//         .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
    await  auth.signInWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

const signOutRequest = async () =>
    await  auth.signOut()
        .then(authUser => authUser)
        .catch(error => error);

const resetPasswordRequest = async (code, password) => 
    await auth.confirmPasswordReset(code, password)
        .catch(error => error);

// function* createUserWithEmailPassword({payload}) {
//     const {email, password} = payload;
//     try {
//         const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
//         if (signUpUser.message) {
//             yield put(showAuthMessage(signUpUser.message));
//         } else {
//             localStorage.setItem('user_id', signUpUser.user.uid);
//             yield put(userSignUpSuccess(signUpUser.user.uid));
//         }
//     } catch (error) {
//         yield put(showAuthMessage(error));
//     }
// }

function* signInUserWithEmailPassword({payload}) {
    const {email, password} = payload;
    try {
        yield put(showAuthLoader());

        const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            localStorage.setItem('user_id', signInUser.user.uid);
            yield put(userSignInSuccess(signInUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }

    yield put(hideAuthLoader());
}

function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
        if (signOutUser === undefined) {
            localStorage.removeItem('user_id');
            yield put(userSignOutSuccess(signOutUser));
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* resetPassword({payload}){
    const {code, password} = payload;
    try {
        yield put(showAuthLoader());
        const ex = yield call(resetPasswordRequest, code, password);
        if(ex !== undefined){
            if(ex.code === "auth/weak-password"){
                yield put(showAuthMessage("Weak password: " + ex.message));
            }else{
                yield put(showAuthMessage("Invalid Reset Password Link. Please try again"));
            }
        }else{
            localStorage.removeItem('user_id');
            yield put(resetPasswordSuccess());
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }

    yield put(hideAuthLoader());
}

// export function* createUserAccount() {
//     yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
// }

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export function* resetUserPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default function* rootSaga() {
    yield all([fork(signInUser),
        // fork(createUserAccount),
        fork(resetUserPassword),
        fork(signOutUser)]);
}