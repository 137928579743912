import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { auth } from "../../firebase/firebase";
import {NotificationContainer, NotificationManager} from 'react-notifications';

const styles = theme => ({
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: "#83c437",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

class UserAccountDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resetPwDialogOpen: false,
      resetEmailSending: false,
      showMessage: false
    }
  }

  componentDidUpdate(){
    if (this.state.showMessage) {
      setTimeout(() => {
        this.setState({ showMessage: false });
      }, 3000);
    }
  }

  // intercept parent toggle to prevent if reset pw is sending
  // TODO: should give consumer chance to decide if they want to block vs cancel
  handleParentClose = () => {
    if(this.state.resetEmailSending) return;
    this.props.onClose();
  }

  showReset = () => {
    this.setState({ resetPwDialogOpen: true });
  }

  // allow quick close if we're not sending the password reset, otherwise force the cancel button
  toggleReset = () => {
    if(this.state.resetEmailSending) return;
    this.setState({ resetPwDialogOpen: false });
  }

  // FBase doesn't appear to actually let us cancel a request, so we'll just close the ui and squint
  cancelReset = () => {
    this.setState({ resetPwDialogOpen: false, resetEmailSending: false });
  }

  sendPasswordEmail = () => {
    if(!auth.currentUser || !auth.currentUser.email){
      console.log('no user found for password reset!');
      return;
    }
    this.setState({ resetEmailSending: true });

    auth
      .sendPasswordResetEmail(auth.currentUser.email)
      .then(() => {
        this.setState({ resetEmailSending: false, resetPwDialogOpen: false, showMessage: true });
        NotificationManager.success("Password reset sent", null, 2000);
      })
      .catch(err => {
        console.log(err);
        this.setState({ resetEmailSending: false, showMessage: true });
        NotificationManager.error("Error sending password reset email", null, 2000);
      });
  }

  messageHidden = () => {
    console.log('message hidden');
    this.setState({ showMessage: false });
  }

  render() {
    const { onClose, isOpen, classes } = this.props;
    const { resetPwDialogOpen, resetEmailSending, showMessage } = this.state;
    const display_name = auth.currentUser.displayName ? auth.currentUser.displayName : "___";
    const profile_url = auth.currentUser.photoURL ? auth.currentUser.photoURL : "http://via.placeholder.com/150";
    const [firstName, lastName] = display_name.split('___');

    return (
      <Modal className="modal-box" isOpen={isOpen} toggle={this.handleParentClose}>
        <ModalHeader className="modal-box-header bg-primary text-white">
          <span>User Account Information</span>
          <IconButton className="text-white" onClick={onClose} id="close-user-account-x">
            <CloseIcon/>
          </IconButton>
        </ModalHeader>
       
        <ModalBody>
          <div className="row no-gutters">
            <div className="col-lg-3 text-center text-lg-right order-lg-2">
              <img className="ml-lg-3 mb-4 mb-lg-0 avatar size-150" src={profile_url} alt="Your Company"/>
            </div>

            <div className="col-lg-9 d-flex flex-column order-lg-1">
              <TextField
                disabled
                label="Username"
                defaultValue={auth.currentUser.email}
                margin="none" />
              <TextField
                disabled
                label="First Name"
                defaultValue={firstName}
                margin="normal" />
              <TextField
                disabled
                label="Last Name"
                defaultValue={lastName}
                margin="normal" />
            </div>
            
            { resetPwDialogOpen &&
            <Modal isOpen={resetPwDialogOpen} toggle={this.toggleReset}>
              <ModalHeader>Reset Password</ModalHeader>
              <ModalBody>
                <p>We will send you a password reset link to the verified email address under this account. Please follow the instructions in that email to reset your password.</p>
                <p>If you have any questions please contact <a href="mailto:noc@qosnet.com">noc@qosnet.com</a></p>
              </ModalBody>
              <ModalFooter>
                <div className={classes.wrapper}>
                  <Button variant="contained" color="primary" onClick={this.sendPasswordEmail} disabled={resetEmailSending} id="send-pw-reset">Send Password Reset</Button>
                  {resetEmailSending && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                <Button variant="contained" color="secondary" onClick={this.cancelReset} id="cancel-pw-reset">Cancel</Button>
              </ModalFooter>
            </Modal>
            }
          </div>
        </ModalBody>

        <ModalFooter>
          {/* <Button variant="contained" color="primary" onClick={onSave}>Save</Button> */}
          <Button variant="contained" color="secondary" onClick={this.showReset} id="request-pw-reset">Reset Password</Button>
        </ModalFooter>

        { showMessage && <NotificationContainer /> }
      </Modal>
    );
  }
}

UserAccountDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default withStyles(styles)(UserAccountDialog);