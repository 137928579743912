import React from 'react';
import {connect} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import UserAccountDialog from '../dialogs/UserAccountDialog';

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
    userAccountDialog: false
  };

  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  handleUserAccount = () => {
    this.setState({userAccountDialog: true, open: false});
  }

  handleUserAccountClose = () => {
    this.setState({userAccountDialog: false});
  }

  handleSignOut = () => {
    this.handleRequestClose();
    this.props.userSignOut()
  };

  render() {
    const { anchorEl, open, userAccountDialog } = this.state;

    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>FLIR Systems <i
            className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
          </h4>
        </div>
        <Menu className="user-info"
              id="simple-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={this.handleRequestClose}
              PaperProps={{
                style: {
                  minWidth: 120,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}
        >
          <MenuItem onClick={this.handleUserAccount} id="open-user-account">
            <i className="zmdi zmdi-pin-account zmdi-hc-fw mr-2"/> User Account
          </MenuItem>
          {/* <MenuItem><i className="zmdi zmdi-settings-square zmdi-hc-fw mr-2"/>Enterprise Settings</MenuItem> */}
          <MenuItem onClick={this.handleSignOut} id="sign-out">
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.logout"/>
          </MenuItem>
        </Menu>
        
        {userAccountDialog &&
          <UserAccountDialog onClose={this.handleUserAccountClose} onSave={this.handleUserAccountClose} isOpen={userAccountDialog} /> }
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale} = settings;
  return {locale}
};
export default connect(mapStateToProps, {userSignOut})(UserInfo);


