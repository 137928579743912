
export default {
    palette: {
        primary: {
            light: "#548598",
            main: "#0b506c",
            dark: "#084059",
            contrastText: '#fff'
        },
        secondary: {
            light: "#b6f769",
            main: "#83c437",
            dark: "#509300",
            contrastText: '#000'
        }
    },
    status: {
        danger: 'orange',
    },
    typography: {
        button: {
            fontWeight: 400,
            textAlign: 'capitalize'
        },
    },
};
